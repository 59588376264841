import { useEffect, useState } from "react";
import { format } from "date-fns-tz";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "components/api/httpService";


const AddRecuoeCaculerSalary = () => {

    const handleSubmit = () => {
        alert('Error');
    };
    
    return (
        <div className="ml-[260px] flex flex-col font-Changa text-textColor">
            <div className="p-5 flex flex-row items-center justify-between">
                <div>
                    <h1 className="font-bold text-3xl">Add Recipe Salary</h1>
                    <div className="flex flex-row">
                        <Link className="text-xl font-semibold leading-6 hover:underline" to="/">Dashboard</Link>
                        <span className="text-[#6c757d] font-xl hover:text-black">/ add recipe salary</span>
                    </div>
                </div>
            </div>

            <div className="container mx-auto p-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    Name
                </label>
                <input
                    type="text"
                    className="mt-1 mb-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />

                <label className="block text-gray-700 text-sm font-bold mb-2">
                    TH1: Tatsächliche Zeit &gt; Vorgesehene Zeit (Monate)
                </label>
                <textarea className="form-textarea mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"></textarea>

                <label className="block text-gray-700 text-sm font-bold mt-4 mb-2">
                    TH2: Tatsächliche Zeit (realistisch) &lt;= Vorgesehene Zeit (Monate) - Standard
                </label>
                <textarea className="form-textarea mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"></textarea>
                <button
                    onClick={handleSubmit}
                    className="bg-blue-500 text-white font-semibold py-2 px-4 rounded shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                    Add
                </button>
            </div>

        </div>
    )
}

export default AddRecuoeCaculerSalary;