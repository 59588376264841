import { useEffect, useState } from "react";
import { format } from "date-fns-tz";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "components/api/httpService";


const ListRecipe = () => {


    return (
        <div className="ml-[260px] flex flex-col font-Changa text-textColor">
            <div className="p-5 flex flex-row items-center justify-between">
                <div>
                    <h1 className="font-bold text-3xl">List Recipe Salary</h1>
                    <div className="flex flex-row">
                        <Link className="text-xl font-semibold leading-6 hover:underline" to="/">Dashboard</Link>
                        <span className="text-[#6c757d] font-xl hover:text-black">/ list recipe salary</span>
                    </div>
                </div>
                <div className="flex flex-row gap-3">
                    <div className="flex flex-row px-4 gap-4">
                        <Link to="/add-recipe-salary" className="bg-buttonColor1 text-white text-base flex flex-row gap-1 justify-center items-center border border-solid p-2 rounded-md hover:bg-cyan-800">
                            <svg style={{ width: '14px', height: '16px' }} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"></path></svg>
                            Add
                        </Link>
                    </div>
                </div>
            </div>

            <div className="container mx-auto p-4">
                <table className="min-w-full bg-white border border-gray-200 rounded-md shadow-md">
                    <thead>
                        <tr className="border-b border-gray-300">
                            <th className="py-2 px-4 text-left text-gray-600">#</th>
                            <th className="py-2 px-4 text-left text-gray-600">Name</th>
                            <th className="py-2 px-4 text-left text-gray-600">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* <tr className="border-b border-gray-200">
                            <td className="py-2 px-4 text-gray-700">1</td>
                            <td className="py-2 px-4 text-gray-700">John Doe</td>
                            <td className="py-2 px-4">
                                <button
                                    className="bg-blue-500 text-white font-semibold py-1 px-2 rounded shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    onClick={() => alert('Edit')}
                                >
                                    Edit
                                </button>
                                <button
                                    className="bg-red-500 text-white font-semibold py-1 px-2 rounded shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 ml-2"
                                    onClick={() => alert('Delete')}
                                >
                                    Delete
                                </button>
                            </td>
                        </tr> */}
                    </tbody>
                </table>
            </div>

        </div>
    )
}

export default ListRecipe;